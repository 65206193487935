.button-group {
    display: flex;
  }
  
  .optionButtons {
    border: 1px solid black !important;
    background-color: white !important;
    color: black !important;
    font-weight: bold !important;
    font-size: 15px !important;
    border-radius: 15px !important;
    margin: 15px !important;
    cursor: pointer !important;
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    transition: 0.5s !important;
    width: 150px !important;
    height: 100px !important;
  }

  .optionButtons:hover{
    transition: 0.5s !important;
    border: 1px solid #D90E28 !important;
    color: #D90E28 !important;
  }